import { z } from 'zod';

const menuItemOverrideSchema = z.object({
  retailItemId: z.number(),
  retailModifiedItemId: z.number(),
  name: z.string(),
  originalPrice: z.number(),
  overridePrice: z.number(),
  originalOverridePrice: z.number(),
  inputOverridePrice: z.number(),
  digitalOnlyFlag: z.boolean(),
  eventDay: z.boolean(),
  overrideLockStatus: z.boolean(),
  adminSuperLock: z.boolean(),
  masterMenuLock: z.boolean(),
  emergencyLockTag: z.string().optional(),
  overrideLockStatusStart: z.string().optional(),
  overrideLockStatusUpdatedBy: z.string().optional(),
  deviceGroupId: z.number().optional(),
  imageUrl: z.string().optional(),
  modified: z.boolean().optional(),
  isCatering: z.boolean().optional(),
  cateringDeliveryOverridePrice: z.number().optional(),
});

export type MenuItemOverride = z.infer<typeof menuItemOverrideSchema>;

export const getMenuItemOverridesResponseSchema = z.object({
  overrides: z.array(menuItemOverrideSchema),
});

type MenuItemOverrides = z.infer<typeof getMenuItemOverridesResponseSchema>;

export default MenuItemOverrides;
