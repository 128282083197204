import { z } from 'zod';

export const substitutionPriceOverrideProfileSchema = z.object({
  name: z.string(),
  id: z.string(),
  count: z.number(),
});

type SubstitutionPriceOverrideProfile = z.infer<
  typeof substitutionPriceOverrideProfileSchema
>;

export default SubstitutionPriceOverrideProfile;
