import { z } from 'zod';

export const deviceGroupItemSchema = z.object({
  imageurl: z.string().url().optional(),
  name: z.string(),
  retailItemId: z.number(),
  retailModifiedItemId: z.number(),
});

export type DeviceGroupItem = z.infer<typeof deviceGroupItemSchema>;

export const menuDeviceGroupSchema = z.object({
  deviceGroupId: z.number(),
  deviceGroupName: z.string(),
  items: z.array(deviceGroupItemSchema),
  lastUpdatedAt: z.number(),
  lastUpdatedBy: z.string(),
  isCustom: z.boolean().optional(),
});

type MenuDeviceGroup = z.infer<typeof menuDeviceGroupSchema>;

export default MenuDeviceGroup;
